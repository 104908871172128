import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import * as z from "zod";

export const ZodSchema = z.object({
  inspectionType: z.enum(["Physical", "Video", "Photo"]),
});
export type InspectionType = z.infer<typeof ZodSchema>;

export const InspectionTypeRadio = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<InspectionType>();

  return (
    <Controller
      control={control}
      name="inspectionType"
      render={({ field }) => (
        <FormControl error={!!errors.inspectionType?.message}>
          {/* <FormLabel
              id="inspection-type-type-label"
            sx={{ textAlign: "left", }}
             >
            Property Type
            </FormLabel> */}
          <RadioGroup
            {...field}
            row
            value={field.value ?? ""}
            // aria-labelledby="inspection-type-type-label"
            name="inspection-type-type-group"
          >
            <FormControlLabel
              value={ZodSchema.shape.inspectionType.Values.Physical}
              control={<Radio size="small" />}
              label="Physical"
            />
            <FormControlLabel
              value={ZodSchema.shape.inspectionType.Values.Video}
              control={<Radio size="small" />}
              label="Video"
            />
            <FormControlLabel
              value={ZodSchema.shape.inspectionType.Values.Photo}
              control={<Radio size="small" />}
              label="Photo"
            />
          </RadioGroup>
          <FormHelperText>
            {errors.inspectionType?.message ?? ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
