import React from "react";
import TextField from "@mui/material/TextField";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { buttonCTA, buttonCancel } from "@/Lib/StyleHelpers";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker } from "@mui/x-date-pickers";
import { InputLabel } from "@mui/material";
import { z } from "zod";
import { ZodSchema as AddressSchema } from "@/Forms/AddressForm";
import colors from "@tim/assets/theme/base/colors";
import { enqueueSnackbar } from "notistack";
import { logError } from "@/Lib/ErrorLogging";
import { requiredBySchema } from "@/Lib/util/zodUtils";

const LeaseDataPage = () => {
  const [submitting, setSubmitting] = React.useState<boolean>();

  const leaseSchema = AddressSchema.extend({
    rentDate: z.date(),
    PageViews: z.string(),
    Leads: z.string().nullable(),
    Showings: z.string().min(1),
    daysOnMarket: z.coerce.number().nonnegative().min(1),
    statusLabel: z.string(),
  });

  type Lease = z.infer<typeof leaseSchema>;
  const leaseMethods = useForm<Lease>({
    mode: "onChange",
    resolver: zodResolver(leaseSchema),
    defaultValues: {
      PageViews: "",
      Leads: "",
      Showings: "",
      statusLabel: "",
    },
  });
  const onSubmit: SubmitHandler<Lease> = (formValues) => {
    setSubmitting(true);
    console.log(formValues);
    const createProject = Promise.resolve("Not implemented");
    enqueueSnackbar({
      message: "Not implemented",
      variant: "error",
    });
    createProject

      .catch((err) => {
        logError(new Error("Add project failure (create from inspection)"), {
          err,
        });
        enqueueSnackbar({
          message:
            "Oops! Something went wrong and project could not be added. The team has been notified",
          variant: "error",
        });
      })
      .finally(() => {
        return setSubmitting(false);
      });
  };
  const { control, handleSubmit } = leaseMethods;
  return (
    <FormProvider {...leaseMethods}>
      <form onSubmit={handleSubmit(onSubmit, console.error)}>
        <div>
          <div style={{ marginBottom: "1vh" }}>Create a Lease</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "40vw",
                backgroundColor: `${colors.white}`,
                height: "40vh",
                boxShadow: "0px 5px 8px #C0C0C0	",
                minWidth: 400,
              }}
            >
              <div style={{ marginTop: "3vh", marginLeft: "1vw" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={12} lg={5}>
                    <Controller
                      name="rentDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <div style={{ width: "12vw", minWidth: 200 }}>
                            <DatePicker
                              {...field}
                              label={"Move in Date?"}
                              value={field.value ?? null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="moveOutDate"
                                  required={requiredBySchema(
                                    leaseSchema,
                                    "rentDate"
                                  )}
                                  error={Boolean(error)}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} lg={7}>
                    <Controller
                      control={control}
                      name="PageViews"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="PageViews"
                            {...field}
                            label="Page Views"
                            required={requiredBySchema(
                              leaseSchema,
                              "PageViews"
                            )}
                            type="text"
                            sx={{ width: "12vw", minWidth: 150 }}
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} lg={5}>
                    <Controller
                      control={control}
                      name="Leads"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="Leads"
                            required={requiredBySchema(leaseSchema, "Leads")}
                            {...field}
                            label="Leads"
                            type="text"
                            sx={{ width: "12vw", minWidth: 150 }}
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="Showings"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="Showings"
                            {...field}
                            required={requiredBySchema(leaseSchema, "Showings")}
                            label="Showings"
                            type="text"
                            sx={{ width: "12vw", minWidth: 100 }}
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} xl={5}>
                    <Controller
                      control={control}
                      name="daysOnMarket"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="daysOnMarket"
                            {...field}
                            label="Days On Market"
                            required={requiredBySchema(
                              leaseSchema,
                              "daysOnMarket"
                            )}
                            type="text"
                            sx={{
                              width: "12vw",
                              minWidth: 130,
                            }}
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} xl={5}>
                    <Controller
                      control={control}
                      name="statusLabel"
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          required={requiredBySchema(
                            leaseSchema,
                            "statusLabel"
                          )}
                        >
                          <InputLabel id="statusLabel">Status</InputLabel>
                          <Select
                            labelId="statusLabel"
                            id="statusLabel"
                            {...field}
                            style={{
                              height: "5vh",
                              width: "12vw",
                              minWidth: 160,
                            }}
                          >
                            <MenuItem value={"Pending Contract"}>
                              Pending Contract
                            </MenuItem>
                            <MenuItem value={"Signed"}>Signed</MenuItem>
                            <MenuItem value={"In Progress"}>
                              In Progress
                            </MenuItem>
                            <MenuItem value={"MIMO in Progress"}>
                              MIMO in Progress
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "1vw",
                  marginRight: "1vw",
                  marginTop: "2vh",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  color="cancel"
                  sx={buttonCancel}
                >
                  Leave
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="cta"
                  sx={buttonCTA}
                  disabled={submitting}
                >
                  {submitting ? <CircularProgress /> : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default LeaseDataPage;

// spell-checker:ignore MIMO
