import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import Home from "./Pages/Dashboard/Home";
import Login from "./Pages/Login";
import QuickSetup from "./Pages/QuickSetup";
import LenderSetup from "./Pages/LenderSetup";
import LenderAdminSetup from "./Pages/LenderAdminSetup";
import { default as ProjectView } from "./Pages/ProjectView";
import { AdminPage } from "./Pages/Admin/Admin";
import { EntityList } from "./Pages/Admin/EntityList";
import { DispatchInspection } from "./Pages/DispatchInspection";
import FileRedirector from "./Pages/FileRedirector";
import { UserList } from "./Pages/Admin/UserList";
import ErrorPage from "./Pages/ErrorPage";
import EditEntity from "./Pages/Admin/EditEntity";
import CreateEntity from "./Pages/Admin/CreateEntity";
import NotFound from "./Pages/NotFound";
import { AdminRoute } from "./Routes";

/**
 * Old UI routing. New UI uses Routes.tsx
 */
export default function RoutesComponent(): JSX.Element {
  return (
    <Routes>
      <Route errorElement={<ErrorPage />} handle="error page layout route">
        {/* public */}
        <Route path="login" handle="login" element={<Login />} />
        <Route
          path="/newuser"
          handle="newuser"
          element={<Login newUserMode />}
        />
        <Route
          path="/dispatchinspector" // spell-checker:ignore dispatchinspector
          handle="dispatchinspector"
          element={<DispatchInspection />}
        />

        {/* everything else authenticated */}
        <Route
          element={
            <AuthenticatedRoute
              prefix=""
              handle="authenticated layout route old school"
            />
          }
        >
          <Route
            path="/"
            element={<Navigate replace to="/portfolio" />}
            errorElement={<ErrorPage />}
            handle="/"
          />
          <Route path="portfolio" element={<Home portfolio />}></Route>
          {/* Compatibility routes for switching between new and old: */}
          <Route
            path="portfolio/todo"
            element={<Navigate to={"/portfolio?view=todo"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/pending"
            element={<Navigate to={"/portfolio?view=pending"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/active"
            element={<Navigate to={"/portfolio?view=active"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/delayed"
            element={<Navigate to={"/portfolio?view=delayed"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/completed"
            element={<Navigate to={"/portfolio?view=completed"} replace />}
          ></Route>
          <Route
            path="dashboard"
            element={<Navigate replace to="/portfolio" />}
          ></Route>
          <Route path="cards" element={<Home cards />}></Route>
          <Route path="lendersetup" element={<LenderSetup />} />
          <Route path="lenderadminsetup" element={<LenderAdminSetup />} />
          <Route path="quicksetup" element={<QuickSetup />} />
          <Route path="projectview/:projectId/*" element={<ProjectView />}>
            <Route
              path=":topic/*"
              element={/* hack handled by ProjectView */ null}
            >
              <Route
                path=":subTopic/*"
                element={/* hack handled by ProjectView */ null}
              ></Route>
            </Route>
          </Route>
          <Route path="admin" element={<AdminRoute prefix="" />}>
            <Route path="" element={<AdminPage />} />
            <Route path="entity" element={<EntityList />} />
            <Route path="entity/:entityId" element={<EditEntity />} />
            <Route path="entity/new" element={<CreateEntity />} />
            <Route path="users/*" element={<UserList />} />
            {/* <AdminRoute path="entity/:id">
        <EntityList />
      </AdminRoute> */}
            {/* <Route>
        <NotFound />
      </Route> */}
          </Route>
          <Route path="/file" element={<FileRedirector />} />
        </Route>
        {/* <Route exact path="/signup">
            <Login signupMode />
          </Route> */}
        {/* <Route
            path={["/dashboard"].concat(HomeRoutes)}
            element={
              <AuthenticatedRoute>
                <Home />
              </AuthenticatedRoute>
            }
          /> */}
        {/* <AuthenticatedRoute roleMustBeNull exact path="/firstTimeLogin">
            <LenderSetup firstTimeLoginMode />
          </AuthenticatedRoute> */}

        {/* <AuthenticatedRoute
            path="/search"
            element={
              {
                <Search />
              }
            }
          /> */}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
