import {
  Navigate,
  Outlet,
  IndexRouteObject,
  NonIndexRouteObject,
  useMatches,
  useNavigation,
} from "react-router-dom";

import AuthenticatedRoute from "./Components/AuthenticatedRoute"; //} // AdminRoute, // , {

import Home from "./Pages/Dashboard/Home";
import Login from "./Pages/Login";
import LenderSetup from "./Pages/LenderSetup";
import LenderAdminSetup from "./Pages/LenderAdminSetup";
import { AdminPage } from "./Pages/Admin/Admin";
import { EntityList } from "./Pages/Admin/EntityList";
import { useAppContext } from "@lib/UserContext";
import { Role } from "@lib/roles";
import { DispatchInspection } from "./Pages/DispatchInspection";
import FileRedirector from "./Pages/FileRedirector";
import { UserList } from "./Pages/Admin/UserList";
import ErrorPage from "./Pages/ErrorPage";
import EditEntity from "./Pages/Admin/EditEntity";
import CreateEntity from "./Pages/Admin/CreateEntity";

import CreateDrawRequestPage from "./Pages/ProjectView/Invoice/CreateDrawRequestPage";
import PendingDrawsPage from "./Pages/ProjectView/Invoice/PendingDrawsPage";
import ApprovedDrawsPage from "./Pages/ProjectView/Invoice/ApprovedDrawsPage";
import RejectedDrawsPage from "./Pages/ProjectView/Invoice/RejectedDrawsPage";
import BudgetPage from "./Pages/ProjectView/Info/BudgetPage";
import LoanInfoPage from "./Pages/ProjectView/Info/LoanInfoPage";
import PropertyInfoPage from "./Pages/ProjectView/Info/PropertyInfoPage";
import UsersPage from "./Pages/ProjectView/Info/UsersPage";
import ProjectReportingPage from "./Pages/ProjectView/Info/ProjectReportingPage";
import ProjectFilesPage from "./Pages/ProjectView/Info/ProjectFilesPage";
import ProjectInvoiceRoutingPage from "./Pages/ProjectView/Info/ProjectInvoiceRoutingPage";
import Logout from "./Pages/Logout";
import { requireAuthenticatedUser } from "./Lib/hooks/useAuth";
import { AdminView } from "./Pages/ProjectView/Admin/ProjectAdminView";
import {
  Project,
  legacyBackendGetProjectDetailsResponse,
} from "./Lib/APITypes";
import { ProfilePage } from "./Pages/ProfilePage";
import { CreateProjectPage } from "./Pages/CreateProjectPage";
import { AdminViewContainer } from "./Components/AdminViewContainer";
import NewRequestPage from "./Pages/NewRequestPage";
import TurnoverPage from "./Pages/TurnoverPage";
import { useAdminData } from "./Lib/AdminContext";

import DashboardLayout from "@tim/examples/LayoutContainers/DashboardLayout";
import { DashboardNavbarWithUser } from "./DashboardNavbarWithUser";
import { loadProject } from "./Lib/hooks/useProjectDetails";
// import { useGetUserRole } from "./Lib/hooks/useGetUserRole";
import { NotFoundSoCreateACard } from "./NotFoundSoCreateACard";
import { HandleStuff } from "./Sidenav";
import { CrumbFunction } from "@tim/examples/Breadcrumbs";
import { SelfManagedSidenav } from "./SelfManagedSidenav";
import { LinearProgress } from "@mui/material";
import LeaseDataPage from "./Pages/LeasePage";
import ActivityPage from "./Pages/ActivityPage";
import TabNavigator from "./Components/TabNavigator";

/**
 * Where to go when someone clicks the home icon or PC logo etc.
 * Typically we would get here via redirects anyway but going direct is faster and avoids flashes
 */
export const home = "/portfolio/todo";

function AuthorizedOutlet({ authorized }: { authorized: boolean }) {
  return authorized ? <Outlet /> : <Navigate replace to="/401" />;
}

function RequireRole({ role: requiredRole }: { role: Role }) {
  const { role } = useAppContext();
  return <AuthorizedOutlet authorized={role === requiredRole} />;
}

export const AdminRoute = ({ prefix }: { prefix: string }) => (
  <AuthenticatedRoute prefix={prefix}>
    <RequireRole role={Role.SuperAdmin} />
  </AuthenticatedRoute>
);

const logoutRoute = "auth/logout";

const MaybeAdminView = () => {
  const matches = useMatches();
  const project = matches.find(({ id }) => id === "projectview")
    ?.data as legacyBackendGetProjectDetailsResponse;
  const { showAdmin, requestAdminView } = useAppContext();
  const { adminData, adminChildren } = useAdminData();

  return showAdmin ? (
    <AdminViewContainer
      initialPosition="bottom"
      isVisible={showAdmin}
      onVisibleChange={() => requestAdminView(!showAdmin)}
      dimMode="transparent"
    >
      <AdminView
        project={project}
        {...matches.reduce((admins, { handle }) => {
          const admin = (handle as { admin?: Record<string, unknown> }).admin;
          return admin ? { ...admins, ...admin } : admins;
        }, {} as Record<string, unknown>)}
        routes={routes()}
        matches={matches}
        {...adminData}
      >
        {Object.entries(adminChildren).map(([key, node]) => node)}
      </AdminView>
    </AdminViewContainer>
  ) : null;
};

const LoadingProgress = () => {
  return useNavigation().state === "loading" ? <LinearProgress /> : null;
};

type RouteObject =
  | IndexRouteObject
  | (Omit<NonIndexRouteObject, "handle" | "children"> & {
      // Omit<ReactRouterRouteObject, "handle"> & {
      handle?: string | HandleStuff;
      children?: Array<RouteObject>;
    });

type ProjectCrumbFunction = (
  params: Omit<Parameters<CrumbFunction>[0], "data"> & {
    data: Project | undefined;
  }
) => ReturnType<CrumbFunction>;

const ProjectCrumbFactory: (fn: ProjectCrumbFunction) => CrumbFunction = (fn) =>
  fn as CrumbFunction;

export const routes: () => Array<RouteObject> = () => {
  return [
    {
      errorElement: <ErrorPage />,
      handle: "error page layout route",
    },
    { index: true, element: <Navigate to="portfolio" />, handle: "index" },

    /* public routes */
    { path: "login", handle: "login", element: <Login /> },
    {
      path: "newuser",
      handle: "newuser",
      element: <Login newUserMode />,
    },
    {
      path: "dispatchinspector", // spell-checker:ignore dispatchinspector
      handle: "dispatchinspector",
      element: <DispatchInspection />,
    },

    /* everything else authenticated */
    {
      element: <AuthenticatedRoute prefix="/new" />,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      // loader: () => useGetUserRole(),
      id: "authenticated",
      handle: {
        tag: "authenticated new layout route",
        // routes: () => timProfileRoutes,
      },
      // loader: ({ params }) =>
      //   /*
      //    * loaders are eager (https://github.com/remix-run/react-router/discussions/9327) even though
      //    * our component will refuse to render, so if we're not ready just fail early,
      //    * quietly, with garbage
      //    */
      //   requireAuthenticatedUser()
      //     .then(() => loadProject(params))
      //     .catch((err) => {
      //       if (err === "The user is not authenticated") {
      //         console.log("not authenticated yet; skipping loader");
      //         return {};
      //       }
      //       throw err;
      //     }),
      children: [
        {
          path: "quicksetup",
          element: <Navigate to="/portfolio/new" replace />,
        },
        {
          element: (
            <>
              <SelfManagedSidenav />
              <DashboardLayout>
                <DashboardNavbarWithUser />
                <Outlet />
                <LoadingProgress />
                <MaybeAdminView />
              </DashboardLayout>
            </>
          ),

          // path: "portfolio/*",
          children: [
            {
              key: "profile",
              name: "Profile",
              element: <ProfilePage />,
              path: "/profile",
              handle: {
                crumb: () => ({ text: "Profile", link: "profile" }),
              },
            },
            {
              path: "new",
              children: [
                {
                  path: "project",
                  element: <CreateProjectPage />,
                  handle: {
                    crumb: () => ({ text: "New Project", link: "new/project" }),
                  },
                },
                {
                  path: "inspection",
                  element: <NewRequestPage inspection={true} />,
                  handle: {
                    crumb: () => ({
                      text: "New Inspection",
                      link: "new/inspection",
                    }),
                  },
                },
                {
                  path: "checkin", // spell-checker:ignore checkin
                  element: <NewRequestPage />,
                  handle: {
                    crumb: () => ({
                      text: "New Check-In",
                      link: "new/checkin", // spell-checker:ignore checkin
                    }),
                  },
                },
                {
                  path: "budget-review",
                  element: <NewRequestPage />,
                  handle: {
                    crumb: () => ({
                      text: "New Budget Review",
                      link: "new/budget-review",
                    }),
                  },
                },
                {
                  path: "turnover",
                  element: <TurnoverPage />,
                  handle: {
                    crumb: () => ({
                      text: "New Turnover",
                      link: "new/turnover",
                    }),
                  },
                },
                { index: true, element: <Navigate to="project" replace /> },
              ],
            },
            {
              path: "portfolio",
              handle: {
                crumb: () => ({ text: "Portfolio", link: "/portfolio" }),
              },
              children: [
                {
                  path: "todo",
                  element: <Home portfolio view="todo" />,
                  handle: {
                    crumb: () => ({ text: "To Do", link: "/portfolio/todo" }),
                  },
                },
                {
                  path: "pending",
                  element: <Home portfolio view="pending" />,
                  handle: {
                    crumb: () => ({
                      text: "Pending",
                      link: "/portfolio/pending",
                    }),
                  },
                },
                {
                  path: "active",
                  element: <Home portfolio view="active" />,
                  handle: {
                    crumb: () => ({
                      text: "Active",
                      link: "/portfolio/active",
                    }),
                  },
                },
                {
                  path: "delayed",
                  element: <Home portfolio view="delayed" />,
                  handle: {
                    crumb: () => ({
                      text: "Delayed",
                      link: "/portfolio/delayed",
                    }),
                  },
                },
                {
                  path: "all",
                  element: <Home portfolio view="all" />,
                  handle: {
                    crumb: () => ({ text: "All", link: "/portfolio/all" }),
                  },
                },
                {
                  path: "complete",
                  element: <Home portfolio view="complete" />,
                  handle: {
                    crumb: () => ({
                      text: "Completed",
                      link: "/portfolio/Complete",
                    }),
                  },
                },
                {
                  path: "cards",
                  element: <Home cards />,
                  handle: {
                    crumb: () => ({
                      text: "Card View",
                      link: "/portfolio/cards",
                    }),
                  },
                },
                {
                  index: true,
                  element: <Navigate to="todo" replace />,
                },
              ],
            },

            {
              path: "projectview",
              children: [
                {
                  id: "projectview",
                  path: ":projectId",
                  loader: ({ params }) =>
                    /*
                     * loaders are eager (https://github.com/remix-run/react-router/discussions/9327) even though
                     * our component will refuse to render, so if we're not ready just fail early,
                     * quietly, with garbage
                     */
                    requireAuthenticatedUser()
                      .then(() => loadProject(params))
                      .catch((err) => {
                        if (err === "The user is not authenticated") {
                          console.log("not authenticated yet; skipping loader");
                          return {};
                        }
                        throw err;
                      }),
                  handle: {
                    //   desc: "project detail",
                    //   visibleTimRoutes: ({
                    //     role,
                    //     match: { pathname },
                    //   }: {
                    //     match: ReturnType<typeof useMatches>[0];
                    //     matches: ReturnType<typeof useMatches>;
                    //     role?: Role;
                    //   }) => {
                    //     return timPortfolioRoutes({ expanded: false }).concat(
                    //       prefixRoutes(timProjectRoutes(role), pathname)
                    //     );
                    //   },
                    // crumb: ({ data }: { data: Project }) => {
                    //   const { name, project_id } = data;
                    //   return {
                    //     link: project_id && `projectview/${project_id}`,
                    //     text: name,
                    //   };
                    // },
                    crumb: ProjectCrumbFactory(
                      ({ data: { project_id, name } = {} }) => ({
                        link: project_id && `projectview/${project_id}`,
                        text: name ?? "(loading)",
                      })
                    ),
                  },
                  children: [
                    { index: true, element: <Navigate to="draws" replace /> },
                    {
                      path: "draws",
                      element: (
                        <>
                          <TabNavigator />
                          <Outlet />
                        </>
                      ),
                      children: [
                        {
                          path: "new",
                          element: <CreateDrawRequestPage />,
                          handle: {
                            crumb: ProjectCrumbFactory(
                              ({ data: { project_id } = {} }) => ({
                                text: "New",
                                link:
                                  project_id && `projectview/${project_id}/new`,
                              })
                            ),
                          },
                        },
                        {
                          path: "pending",
                          element: <PendingDrawsPage />,
                          handle: {
                            crumb: ProjectCrumbFactory(
                              ({ data: { project_id } = {} }) => ({
                                text: "Pending",
                                link:
                                  project_id &&
                                  `projectview/${project_id}/pending`,
                              })
                            ),
                          },
                        },
                        {
                          path: "approved",
                          element: <ApprovedDrawsPage />,
                          handle: {
                            crumb: ProjectCrumbFactory(
                              ({ data: { project_id } = {} }) => ({
                                text: "Approved",
                                link:
                                  project_id &&
                                  `projectview/${project_id}/approved`,
                              })
                            ),
                          },
                        },
                        {
                          path: "rejected",
                          element: <RejectedDrawsPage />,
                          handle: {
                            crumb: ProjectCrumbFactory(
                              ({ data: { project_id } = {} }) => ({
                                text: "Rejected",
                                link:
                                  project_id &&
                                  `projectview/${project_id}/rejected`,
                              })
                            ),
                          },
                        },
                        {
                          path: "routing",
                          handle: {
                            crumb: ProjectCrumbFactory(
                              ({ data: { project_id } = {} }) => ({
                                text: "Default Draw Routing",
                                link:
                                  project_id &&
                                  `projectview/${project_id}/draws/routing`,
                              })
                            ),
                          },
                          element: <ProjectInvoiceRoutingPage />,
                        },
                        {
                          index: true,
                          element: <Navigate to="pending" replace />,
                        },
                      ],
                    },

                    {
                      path: "budget",
                      element: <BudgetPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Budget",
                            link:
                              project_id && `projectview/${project_id}/budget`,
                          })
                        ),
                      },
                    },
                    {
                      path: "loan",
                      element: <LoanInfoPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Loan",
                            link:
                              project_id && `projectview/${project_id}/loan`,
                          })
                        ),
                      },
                    },
                    {
                      path: "activity",
                      element: <ActivityPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Activity",
                            link:
                              project_id &&
                              `projectview/${project_id}/activity`,
                          })
                        ),
                      },
                    },
                    {
                      path: "turnover",
                      element: <TurnoverPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Turnover",
                            link:
                              project_id &&
                              `projectview/${project_id}/Turnover`,
                          })
                        ),
                      },
                    },
                    {
                      path: "lease",
                      element: <LeaseDataPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Lease Info",
                            link:
                              project_id && `projectview/${project_id}/lease`,
                          })
                        ),
                      },
                    },
                    {
                      path: "property",
                      element: <PropertyInfoPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Property Info",
                            link:
                              project_id &&
                              `projectview/${project_id}/property`,
                          })
                        ),
                      },
                    },
                    {
                      path: "users",
                      element: <UsersPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Users",
                            link:
                              project_id && `projectview/${project_id}/users`,
                          })
                        ),
                      },
                    },
                    {
                      path: "reporting",
                      element: <ProjectReportingPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Reporting",
                            link:
                              project_id &&
                              `projectview/${project_id}/reporting`,
                          })
                        ),
                      },
                    },

                    {
                      path: "files",
                      element: <ProjectFilesPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Files",
                            link:
                              project_id && `projectview/${project_id}/files`,
                          })
                        ),
                      },
                    },
                    {
                      path: "lease",
                      element: <LeaseDataPage />,
                      handle: {
                        crumb: ProjectCrumbFactory(
                          ({ data: { project_id } = {} }) => ({
                            text: "Lease Info",
                            link:
                              project_id && `projectview/${project_id}/lease`,
                          })
                        ),
                      },
                    },

                    {
                      path: "Tasks",
                      element: (
                        <Navigate
                          to="../draws/pending"
                          replace
                          relative="path"
                        />
                      ),
                      handle: "backward compatibility for /Tasks",
                    },
                    {
                      path: "Budget",
                      element: (
                        <Navigate to="../budget" replace relative="path" />
                      ),
                      handle: "backward compatibility for /Budget",
                    },
                  ],
                },
              ],
            },

            // {
            //   element: (
            //     <div>
            //       {/* <Typography>Backward Compatibility</Typography> */}
            //       <BackwardCompatiblePortfolioNav />
            //     </div>
            //   ),
            //   path: "*",
            //   handle: "no match under new",
            // },

            { path: "lendersetup", element: <LenderSetup /> },
            { path: "lenderadminsetup", element: <LenderAdminSetup /> },

            {
              path: "admin",
              element: <AdminRoute prefix="" />,
              handle: {
                crumb: () => ({ text: "Admin", link: "admin" }),
              },
              children: [
                { index: true, element: <AdminPage /> },
                {
                  path: "entity",
                  handle: {
                    crumb: () => ({ text: "Entity", link: "admin/entity" }),
                  },
                  children: [
                    { index: true, element: <EntityList /> },
                    { path: ":entityId", element: <EditEntity /> },
                    { path: "new", element: <CreateEntity /> },
                  ],
                },
                {
                  path: "users",
                  handle: {
                    crumb: () => ({ text: "Users", link: "admin/users" }),
                  },
                  children: [{ index: true, element: <UserList /> }],
                },
              ],
            },
            { path: "file", element: <FileRedirector /> },
            { path: logoutRoute, element: <Logout /> },
            // compatibility routes new/old UI
            {
              path: "portfolio?view=active",
              element: <Navigate replace to={"/portfolio/active"} />,
            },
            {
              path: "dashboard",
              element: <Navigate replace to={"/portfolio"} />,
            },
          ],
        },
      ],
    },
    { path: "*", element: <NotFoundSoCreateACard /> },
  ];
};
