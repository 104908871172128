import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { FormProvider, useForm } from "react-hook-form";
import React from "react";
import { useSnackbar } from "notistack";
import { Button, Grid } from "@mui/material";
import ProjectInfoForm, {
  ProjectInfoFormZodSchema,
} from "../../../Forms/ProjectInfoForm";
import { Prompt } from "../../../Components/Prompt";
import { ErrorOrProgressOr } from "../../../Components/ErrorOrProgressOr";
import { zodResolver } from "@hookform/resolvers/zod";
import { legacyBackendGetProjectDetailsResponse } from "@lib/APITypes";
import colors from "@tim/assets/theme/base/colors";

export interface ProjectInfoTabProps {
  /** We'll fetch with SWR but hopefully it's already ready :-) */
  // TODO: make getprojects and getprojectdetails cross-update each other - as long as they currently return the same content
  projectId: string;
}

/**
 * Show a project. Fetches and updates via useProjectDetails
 */
export function ProjectInfoTab({
  projectId,
}: ProjectInfoTabProps): JSX.Element {
  const { project, errors, isValidating } = useProjectDetails(projectId);

  const [reFetching, setReFetching] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm<
    Omit<legacyBackendGetProjectDetailsResponse, "location">
  >({
    mode: "onChange",
    resolver: zodResolver(ProjectInfoFormZodSchema),
    defaultValues: project?.details,
  });
  const { handleSubmit, formState, reset } = formMethods;
  const { isDirty, dirtyFields } = formState;

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(
          (updates) => {
            setReFetching(true);
            return project
              ?.update({
                ...updates,
                Bath: null,
                Bed: null,
                Laundry: null,
                onSiteParking: null,
              })
              .then(() => {
                reset(updates);
              })
              .catch((err) => {
                console.log(err);
                if (err.response?.status === 412) {
                  enqueueSnackbar(
                    "It looks like someone else may have already edited this project. Please try again.",
                    { variant: "warning" }
                  );
                } else {
                  enqueueSnackbar("Error updating project", {
                    variant: "error",
                  });
                }
              })
              .finally(() => {
                setReFetching(false);
              });
          },
          (invalid) => {
            console.log(invalid);
          }
        )}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={2}
          style={{
            width: "40vw",
            backgroundColor: `${colors.white}`,
            height: "65vh",
            boxShadow: "0px 5px 8px #C0C0C0	",
            minWidth: 400,
            marginTop: "2vh",
          }}
        >
          <Grid item>
            <ProjectInfoForm />
          </Grid>
          <Grid item /* spacer */ />
          <Grid item>
            <ErrorOrProgressOr
              error={errors?.details?.message}
              progress={reFetching}
              render={() => (
                <Button
                  type="submit"
                  color="primary"
                  disabled={Boolean(isValidating) || !isDirty}
                >
                  Update
                </Button>
              )}
            />
          </Grid>
        </Grid>
      </form>
      {/* <DevTool control={formMethods.control} /> */}
      <Prompt
        message={`Your project changes are not saved. Do you really want to leave?`}
        when={
          isDirty &&
          /* this is a hack but seems to work and I'm lazy see https://github.com/react-hook-form/react-hook-form/issues/4740 */ Object.keys(
            dirtyFields
          ).length > 0
        }
      />
    </FormProvider>
  );
}
